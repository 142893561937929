import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaLightbulb } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const PlanningService = ({ data }) => {
  const seoData = {
    title:
      'ウェブコンテンツ企画立案協力｜奈良の広告代理店向けサービス｜インヴォルブ',
    description:
      '広告代理店様向けに、ウェブコンテンツの企画立案をサポート。ウェブの知識が不足していても、最適な企画・提案を一緒に考えます。',
    pageUrl: 'https://www.involve-in.jp/service/planning',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'ウェブコンテンツ企画立案協力', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes;

  return (
    <>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="ウェブコンテンツ企画立案協力"
        subtitle="最適なウェブ企画を一緒に考える"
        icon={FaLightbulb}
        description="広告代理店様向けに、ウェブコンテンツの企画立案をサポート。ウェブの知識が不足していても、最適な企画・提案を一緒に考えます。"
        seo={seoData}
        breadcrumbs={breadcrumbList}
        benefits={[
          {
            title: 'ウェブに詳しくなくても安心',
            description:
              '実現可能なアイデアを一緒に考え、具体的な提案に落とし込みます。',
          },
          {
            title: 'ターゲットに刺さる構成設計',
            description:
              '訴求力のあるウェブコンテンツを設計し、企画の精度を向上。',
          },
          {
            title: '広告代理店向けの豊富な実績',
            description: '多くの広告代理店と協力し、最適な提案を支援。',
          },
        ]}
        flow={[
          {
            title: 'ヒアリング',
            duration: '1日',
            description: 'ターゲットや目的を詳しく伺い、方向性を決定。',
          },
          {
            title: 'コンテンツ企画立案',
            duration: '3日〜',
            description: 'アイデアを整理し、実現可能な企画を作成。',
          },
          {
            title: '提案書作成',
            duration: '1週間',
            description: '視覚的に分かりやすい提案書を作成。',
          },
          {
            title: '最終調整・納品',
            duration: null,
            description: '細かな修正を行い、最適な形で提案を仕上げる。',
          },
        ]}
        preparation={[
          {
            title: '企画の目的を明確化',
            description: 'ウェブコンテンツの目的を整理し、方向性を決定。',
          },
          {
            title: 'ターゲット層の確認',
            description: 'どのユーザーをターゲットにするのか明確に。',
          },
          {
            title: '競合のリサーチ',
            description: '競合サイトや類似コンテンツを分析し、差別化を検討。',
          },
          {
            title: '参考資料の準備',
            description: '過去の企画書やイメージ資料を用意。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null,
        }))}
        price="¥0〜"
        cta="企画を相談する"
      />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default PlanningService;
